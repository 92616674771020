





























import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/common/Modal.vue'
import Timer from '@/components/common/Timer.vue'
import CleanPath from '@/helper/clean-path'
import { bus } from '@/components/common/Chat.vue'

@Component({
  components: {
    Modal,
    Timer
  }
})
export default class ScreenThree extends Vue {
  $refs!: Vue['$refs'] & { timer: Timer }
  private isModal= false
  private countdown = 60
  private isEnd = false

  private runTimer (): number {
    if (sessionStorage.getItem('timer/quidel/2') === 'isDone') return 0
    if (!sessionStorage.getItem('timer' + CleanPath(this.$route.path, this.$route.params.name))) sessionStorage.setItem('timer' + CleanPath(this.$route.path, this.$route.params.name), 'isRun')
    if (sessionStorage.getItem('time' + CleanPath(this.$route.path, this.$route.params.name))) return Number(sessionStorage.getItem('time' + CleanPath(this.$route.path, this.$route.params.name)))
    else return this.countdown
  }

  private onlyDesktop (): boolean {
    return window.innerWidth > 767
  }

  private created (): void {
    if (sessionStorage.getItem('timer/quidel/4') === 'isRun') {
      this.$root.$emit('line-timer', {
        visibility: true,
        timeName: 'time/quidel/4'
      })
    } else {
      this.$root.$emit('line-timer', {
        visibility: false,
        timeName: 'time/quidel/4'
      })
    }
  }

  private mounted (): void {
    bus.$emit('PAGE_LOADED')
  }

  private timeIsEnd () :void{
    this.isEnd = true
    sessionStorage.setItem('timer' + CleanPath(this.$route.path, this.$route.params.name), 'isDone')
  }

  private resetTimer ():void {
    this.isEnd = false
    this.$refs.timer.startTimer(this.countdown)
    this.isModal = false
    sessionStorage.setItem('timer' + CleanPath(this.$route.path, this.$route.params.name), 'isRun')
    this.$root.$emit('line-timer', {
      visibility: false,
      timeName: 'time/quidel/2'
    })
    sessionStorage.removeItem('timer/quidel/4')
    sessionStorage.removeItem('time/quidel/4')
  }

  private destroyed (): void {
    if (sessionStorage.getItem('timer/quidel/2') !== 'isDone') {
      this.$root.$emit('line-timer', {
        visibility: true,
        timeName: 'time/quidel/2'
      })
    }
  }
}
